import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import LinkButton from "./LinkButton";
import Button from "./Button";
import Select from "./Select";
import { getObjectFromArrayById } from "../../utils/common";
import OrderService from "../../services/OrderService";
import { getHrefs } from "../../utils/langService";

const useConfig = ({ variant }) => {
  const [variantId, setVariantId] = useState(variant[0].id);
  const [variantData, setVariantData] = useState(variant[0]);

  const updateVariantId = e => {
    setVariantId(e.target.value);
  };

  useEffect(() => {
    setVariantData(getObjectFromArrayById(variant, variantId));
  }, [variantId, setVariantData, variant]);

  return [variantData, variantId, updateVariantId];
};

const ProductBox = ({ children, t, lang, data, link, high }) => {
  const [variantData, variantId, updateVariantId] = useConfig({ variant: data.variant });

  const { pages } = getHrefs(lang);

  const addToShoppingCart = () => {
    const productId = `${data.id}${variantData.id}`;
    OrderService.createOrder({ productId, quantity: 1, modifier: [], divide: [] });
    navigate(`/${lang}/${pages[7]}/`);
  };

  return (
    <div className="ProductBox">
      <div>
        <div className={`ProductBox__header ${!children ? "ProductBox__header--withoutBorder" : ""} ${high ? "ProductBox__header--high" : ""}`}>
          <h4 className="ProductBox__title">{variantData.name}</h4>
          {!!t.description && <p className="ProductBox__description">{t.description}</p>}

          {data.variant.length > 1 && (
            <div className="ProductBox__configBox">
              <Select options={data.variant} name="product-config" value={variantId} onChange={updateVariantId} />
            </div>
          )}
        </div>

        {!!children && <div className="ProductBox__children">{children}</div>}
      </div>

      <div className={`ProductBox__footer ${!children ? "ProductBox__footer--withoutBorder" : ""}`}>
        <div className="ProductBox__priceContainer">
          <p>{t.price.info}</p>
          <p className="ProductBox__price">
            <span>{variantData.price.year / 12}</span> {variantData.price.currency}
          </p>
          <p>{t.price.period}</p>
        </div>

        {!!link ? <LinkButton to={link}>{t.btn}</LinkButton> : <Button onClick={addToShoppingCart}>{t.btn}</Button>}
      </div>
    </div>
  );
};

ProductBox.propTypes = {
  children: PropTypes.node,
  t: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  link: PropTypes.string,
  high: PropTypes.bool
};

export default ProductBox;
