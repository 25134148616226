import Vps from "./Vps";
import { connect } from "react-redux";
import { getVPSList } from "../../../../store/packages/vps/actions";

const mapStateToProps = state => ({
  vps: state.vps
});

const mapDispatchToProps = dispatch => ({
  getVPSList: async () => dispatch(await getVPSList())
});

const VpsContainer = connect(mapStateToProps, mapDispatchToProps)(Vps);
export default VpsContainer;
