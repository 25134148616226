import React from "react";
import PropTypes from "prop-types";

const Select = ({ options, labelText, ...attributes }) => (
  <label className="Select">
    <p className="Input__labelText Select__labelText">{labelText}</p>
    <select {...attributes}>
      {options.map((elem, i) => (
        <option key={i} value={elem.id}>
          {elem.value}
        </option>
      ))}
    </select>
  </label>
);

Select.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  labelText: PropTypes.string
};

export default Select;
